import firebase from "firebase";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDcH2LdMWE2RL4P-fFxRvC6y1fJTiMaWc4",
  authDomain: "nbrief-fff82.firebaseapp.com",
  projectId: "nbrief-fff82",
  storageBucket: "nbrief-fff82.appspot.com",
  messagingSenderId: "407561530106",
  appId: "1:407561530106:web:a949f7ba443776d5e4b743",
  measurementId: "G-4DHQ9D9TLT",
};

const firebaseConfig = firebase.initializeApp(config);

export { firebaseConfig as firebaseApp };
