import styled from "styled-components";
import { Colors } from "../../../../../../styles/index";

export const ColumnContainer = styled.div`
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1em 1em 1em;
  //   border: 1px solid ${Colors.highlight4};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
