import styled from 'styled-components';
import { device } from '../../styles/index';

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #4f5d75;
  margin-left: 20px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  height: 40px;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  line-height: 2;
  color: rgba(43,46,50,.85); 
  font-size: 16px;
`;

export const Container = styled.div`
  @media ${device.mobileS} {
    
    width: 80%;
  }

  @media ${device.tabletL} {

    width: 100%;
  }
    max-width: 1024px;

    margin: auto;
    display: flex;
    justify-content: center;
`;

export const SummaryImage = styled.div`
    float: right;
    display: block;
    width: 70%;

`;
