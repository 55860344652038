import React from "react";
import { CardContainer, Title } from "./styles";

const ProjectCard = ({ selected, src, setProject, project }) => {
  return (
    <CardContainer onClick={() => setProject(project)}>
      <img src={src} />
      <Title active={selected && selected.title === project.title}>
        {project && <p>{project.title}</p>}
      </Title>
    </CardContainer>
  );
};

export default ProjectCard;
