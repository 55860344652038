import styled from "styled-components";
import { Colors } from "../../../../../../styles/index";
export const Heading = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  & h4 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${Colors.primary};
  }

  width: 100%;
  padding: 1.2em;
  border-bottom: 1px solid ${Colors.highlight4};
`;

export const Container = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${Colors.highlight4};
  // box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
  margin-bottom: 1rem;
  border-radius: 5px;
`;

export const Row = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  & p {
    font-size: 14px;
  }

  transition: 0.2s;
  padding: 1rem;
  &:hover {
    cursor: pointer;
    background-color: ${Colors.highlight4};
  }
`;

export const Title = styled.h5`
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.primary};
  margin: 0.5em 0 0.5em 0;
`;

export const Description = styled.p`
  margin: 0px !important;
  color: #ababab;
  font-weight: 600;
  color: ${Colors.highlight1};
`;
