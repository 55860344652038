import styled from 'styled-components';
import { device } from '../../styles/index';

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #4f5d75;
  margin-left: 20px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  height: 40px;
  font-weight: 600;
`;

export const TextArea = styled.textarea`
  height: 75%;
  rows: 25;
  cols: 60;
  placeholder: "Type or Paste in Text... "
`;

export const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: 70vh;
`;
export const Outer = styled.div`
  @media ${device.mobileS} {
      
    width: 80%;
  }

  @media ${device.tabletL} {

    width: 100%;
  }
  max-width: 1024px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  & h2{
    color: #4f5d75;
  }
`;

export const NavItem = styled.p`
font-size: 16px;
position: relative;
margin: 0px;
margin-left: 20px;
margin-right: 20px;
transition: 0.2s;
width: fit-content;
text-align: center;
color: white;
border-bottom: 2px solid #4f5d75;


&:hover {
    border-bottom: 2px solid #ef8354;
    cursor: pointer;

}

&:hover a {
  color: rgba(31, 45, 61, 1);
}
`;

export const NavItemBottom = styled.p`
font-size: 16px;
position: relative;
margin: 0px;
margin-left: 20px;
margin-right: 20px;
transition: 0.2s;
width: fit-content;
text-align: center;
color: white;
border-bottom: 2px solid #4f5d75;


&:hover {
    border-bottom: 2px solid #ef8354;
    cursor: pointer;

}

&:hover a {
  color: rgba(31, 45, 61, 1);
}
`;

export const List = styled.div`
  width: 20%;

  padding-top: 2rem;
  background-color: #4f5d75;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  & a {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  width: 80%;
  height: auto;
  padding: 1rem;
  border: 1px solid #dddddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ListItem = styled.div`

  padding: 1rem;
  transition: 0.2s;
  background: ${(props) => (props.active ? 'rgba(255, 255, 255, 0.1)' : 'none')};
  &:hover{
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;
