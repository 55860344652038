import styled from "styled-components";
import { device } from "../../styles/index";

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #4f5d75;
  margin-left: 20px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  line-height: 2;
  font-size: 16px;
`;

export const Container = styled.div`
  max-width: 1024px;
  @media ${device.mobileS} {
    width: 80%;
  }

  @media ${device.tabletL} {
    width: 100%;
  }

  margin: auto;
  display: flex;
  justify-content: center;
`;

export const ProductImages = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  backgroundcolor: "green";
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  align-content: center;
  padding: 30px 20px;
`;

export const HeaderImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  align-self: center;
  justify-self: center;
  padding: 10px;
`;
