import React from "react";
import { AiOutlineRead, AiOutlineHome } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { SidebarContainer, ListItem, ListIcon, Item } from "./styles";

const Sidebar = ({ setTab }) => {
  return (
    <SidebarContainer>
      <Link style={{ textDecoration: "none" }} to="/dashboard/feed">
        <ListItem onClick={() => setTab("feed")}>
          <ListIcon>
            <IconContext.Provider
              value={{
                size: "1.5em",
                style: {
                  verticalAlign: "middle",
                  transition: "0.2s",
                },
              }}
            >
              <AiOutlineHome />
            </IconContext.Provider>
            <Item>Home</Item>
          </ListIcon>
        </ListItem>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/dashboard/shared">
        <ListItem onClick={() => setTab("pdf")}>
          <ListIcon>
            <IconContext.Provider
              value={{
                size: "1.5em",
                style: {
                  verticalAlign: "middle",
                  transition: "0.2s",
                },
              }}
            >
              <AiOutlineRead />
            </IconContext.Provider>
            <Item>Group Projects</Item>
          </ListIcon>
        </ListItem>
      </Link>

      <ListItem>
        <ListIcon>
          <IconContext.Provider
            value={{
              size: "1.5em",
              style: {
                verticalAlign: "middle",
                transition: "0.2s",
              },
            }}
          >
            <BsFillPeopleFill />
          </IconContext.Provider>
          <Item>Individual Projects</Item>
        </ListIcon>
      </ListItem>
    </SidebarContainer>
  );
};

export default Sidebar;
