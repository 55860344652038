import styled from 'styled-components'

export const Paragraph = styled.p`
  line-height: 2;
  font-size: 19px;
  color: rgba(43,46,50,.85); 

`

export const Container = styled.div`
    width: 100%;
    max-width: 1024px; 
    margin: auto;
    display: flex;
    justify-content: center;

`
export const Heading = styled.p`
`
export const SignupDescription = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  backgroundcolor: "green";
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px 40px; 
  justify-content: center;
  align-content: center;
  padding: 120px 0px;
`

export const Button = styled.button`
    padding: 8px 20px;
    border-radius: 4px;
    background-color: #ef8354;
    border: none;
    color: #ffffff;
    font-size: 16px;
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    height: 50px;
    width: 200px;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    margin: auto;
  `
  export const Input = styled.input`
  width: 100%; 
  margin-bottom: 20px; 
  background-color: #fff;
  padding: 0.75rem 0 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8492a6;
  transition: 0.2s;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  border-radius: 0.25rem;
  font-family: 'Inter', sans-serif;


  &:focus {
    outline: 0;
    border-color: #ef8354;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
  }
`;
