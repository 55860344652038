import React, { useState, } from 'react';
import { Button, Container } from './styles'
import { TextArea, Input } from '../Video/styles'
import Spinner from '../../components/Spinner'
import '../../global.js'


const startText = `https://en.wikipedia.org/wiki/War_and_Peace`


function Article() {

  const [loading, setLoading] = useState(false);
  const [articleURL, setArticleURL] = useState(startText);
  const [summaryText, setSummaryText] = useState("");


  const summarizeText = async text => {

    if (text !== "")
      setLoading(true);

    const data = {'text': text, 'num_sentences': global.summaryLength, 'algorithm': global.algorithm}

    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(process.env.REACT_APP_INBRIEF_API + '/get_article', settings);
    // const response = await fetch('/get_article', settings);

    const summary = await response.json();
    setLoading(false)
    setSummaryText(summary.output);
  }

    return (
        <div>

          <Container>
            <p>
              Enter your URL into the left hand box and click "Summarize" to see a quick summarization! This demo
              makes a quick call to a backend API which implements a user-chosen method to summarize the text. Dynamic summaries coming soon.
            </p>
          </Container>
          <Container>
            <Input name='text' cols="120" placeholder="Type or Paste Text..." value={articleURL} onChange={event => setArticleURL(event.currentTarget.value)}></Input>
          </Container>
          <Container>
            <Button onClick={() => summarizeText(articleURL)}>
               Summarize
            </Button>
            {loading ? <Spinner /> : <br />}
          </Container>


          <Container>
            <TextArea rows="25" cols="60" placeholder="Output..." value={summaryText} readOnly></TextArea>
          </Container>
        </div>
    );
}

export default Article
