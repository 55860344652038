import React from "react";
import { FeedContainer } from "./styles";
import ProjectCard from "../ProjectCard";
import img from "../ProjectCard/document.png";
const SourcesView = () => {
  return (
    <FeedContainer>
      <ProjectCard src={img} setProject={() => console.log("test")} />
      <ProjectCard src={img} setProject={() => console.log("test")} />
      <ProjectCard src={img} setProject={() => console.log("test")} />
      <ProjectCard src={img} setProject={() => console.log("test")} />
    </FeedContainer>
  );
};

export default SourcesView;
