import React from "react";
import { Link } from "react-router-dom";
import { Container, Paragraph, ProductImages, HeaderImage } from "./styles";
import save from "../../images/save.svg";
import bookmarks from "../../images/bookmarks.svg";
import reading from "../../images/reading.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function Product() {
  return (
    <>
      <Navbar background />

      <Container>
        <div
          className="nine columns main-col"
          style={{ width: "100%", maxWidth: "1024px" }}
        >
          <h2>Product</h2>

          <Paragraph>
            nBrief presents an <Link to="/demo"> free tool</Link> for all to
            easily summarize inputs of any media type. Though in its demo state,
            the tool is still useful for static summaries of text, article, and
            scanned inputs. This page will be updated when more features are
            added, so stay tuned!
          </Paragraph>

          <ProductImages>
            <HeaderImage src={reading} alt="reading" />
            <HeaderImage src={save} alt="save" />
            <HeaderImage src={bookmarks} alt="bookmarks" />
          </ProductImages>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Product;
