import React, { useState, useReducer } from "react";
import { Auth } from "aws-amplify";
import {
  Container,
  Inner,
  Input,
  Inputs,
  Button,
  Link,
  Toggle,
  ToggleOption,
  FormInner,
} from "./styles";

const loginState = {
  loginEmail: "",
  loginPassword: "",
  signupEmail: "",
  signupPassword: "",
  signupConfirmPassword: "",
  confirmationCode: "",
};

const loginReducer = (currstate, action) => {
  switch (action.type) {
    case "LOGINEMAIL":
      return {
        ...currstate,
        loginEmail: action.content,
      };
    case "LOGINPASSWORD":
      return {
        ...currstate,
        loginPassword: action.content,
      };
    case "SIGNUPEMAIL":
      return {
        ...currstate,
        signupEmail: action.content,
      };
    case "SIGNUPPASSWORD":
      return {
        ...currstate,
        signupPassword: action.content,
      };
    case "SIGNUPCONFIRMPASSWORD":
      return {
        ...currstate,
        signupConfirmPassword: action.content,
      };
    case "CONFIRMCODE":
      return {
        ...currstate,
        confirmationCode: action.content,
      };
    default:
      throw Error("Invalid type");
  }
};

const Login = () => {
  const [toggle, setToggle] = useState(true);
  const [confirmation, toggleConfirmation] = useState(false);
  const [form, formDispatch] = useReducer(loginReducer, loginState);

  const signIn = async () => {
    try {
      const user = await Auth.signIn(form.loginEmail, form.loginPassword);
      console.log(user);
    } catch (error) {
      console.log(form.loginEmail);
      console.log("error signing in", error);
    }
  };

  async function signUp() {
    try {
      const { user } = await Auth.signUp({
        username: form.signupEmail,
        password: form.signupPassword,
        attributes: {
          email: form.signupEmail,
        },
      });
      toggleConfirmation(true);
      console.log(user);
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  const confirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(form.signupEmail, form.confirmationCode);
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  };

  return (
    <Container>
      {!confirmation && (
        <Inner>
          <Toggle>
            <ToggleOption
              filled={toggle === true}
              onClick={() => setToggle(true)}
            >
              LOG IN
            </ToggleOption>
            <ToggleOption
              filled={!toggle === true}
              onClick={() => setToggle(false)}
            >
              SIGN UP
            </ToggleOption>
          </Toggle>
          {toggle && (
            <FormInner>
              <Inputs>
                <Input
                  onChange={(e) =>
                    formDispatch({
                      type: "LOGINEMAIL",
                      content: e.target.value,
                    })
                  }
                  placeholder="Email"
                />
                <Input
                  onChange={(e) =>
                    formDispatch({
                      type: "LOGINPASSWORD",
                      content: e.target.value,
                    })
                  }
                  placeholder="Password"
                  type="password"
                />
              </Inputs>
              <Button onClick={() => signIn()}>LOG IN</Button>
              <Link>Forgot Password?</Link>
            </FormInner>
          )}
          {!toggle && (
            <FormInner>
              <Inputs>
                <Input
                  onChange={(e) =>
                    formDispatch({
                      type: "SIGNUPEMAIL",
                      content: e.target.value,
                    })
                  }
                  placeholder="Email"
                />
                <Input
                  onChange={(e) =>
                    formDispatch({
                      type: "SIGNUPPASSWORD",
                      content: e.target.value,
                    })
                  }
                  placeholder="Password"
                  type="password"
                />
                <Input
                  onChange={(e) =>
                    formDispatch({
                      type: "SIGNUPCONFIRMPASSWORD",
                      content: e.target.value,
                    })
                  }
                  placeholder="Confirm Password"
                  type="password"
                />
              </Inputs>
              <Button onClick={() => signUp()}>SIGN UP</Button>
            </FormInner>
          )}
        </Inner>
      )}
      {confirmation && (
        <Inner>
          <FormInner>
            <p>
              A six digit confirmation code has been sent to your email. Please
              enter in your code to confirm your account.
            </p>
            <Inputs>
              <Input
                onChange={(e) =>
                  formDispatch({ type: "CONFIRMCODE", content: e.target.value })
                }
                placeholder="Confirmation Code"
              />
            </Inputs>
            <Button onClick={() => confirmSignUp()}>CONFIRM ACCOUNT</Button>
          </FormInner>
        </Inner>
      )}
    </Container>
  );
};

export default Login;
