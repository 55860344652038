import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  border-radius: 5px;
`;

export const Heading = styled.h2`
  margin-top: 0px;
`;
