import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import {
  ContentContainer,
  Container,
  NavItem,
  List,
  ListItem,
  Content,
  Outer,
} from "./styles";
import Text from "../Text";
import Scan from "../Scan";
import Article from "../Article";
import Video from "../Video";
import Options from "../Options";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Demo = () => {
  const [active, setActive] = useState("");

  return (
    <>
      <Navbar background />

      <Outer>
        <Container>
          <h2>Demo section</h2>
        </Container>
        <Container>
          <p>
            Pick any of the 4 media types below to get started with
            summarization!
          </p>
        </Container>
        <ContentContainer>
          <List>
            <Link to="/demo/text">
              <ListItem
                active={active === "text"}
                onClick={() => setActive("text")}
              >
                <NavItem>Text</NavItem>
              </ListItem>
            </Link>
            <Link to="/demo/scan">
              <ListItem
                active={active === "scan"}
                onClick={() => setActive("scan")}
              >
                <NavItem>Scan</NavItem>
              </ListItem>
            </Link>
            <Link to="/demo/article">
              <ListItem
                active={active === "article"}
                onClick={() => setActive("article")}
              >
                <NavItem>Article</NavItem>
              </ListItem>
            </Link>
            <Link to="/demo/video">
              <ListItem
                active={active === "video"}
                onClick={() => setActive("video")}
              >
                <NavItem>Video</NavItem>
              </ListItem>
            </Link>
            <br />
            <br />
            <br />
            <br />
            <Link to="/demo/options">
              <ListItem
                active={active === "options"}
                onClick={() => setActive("options")}
              >
                <NavItem>Options</NavItem>
              </ListItem>
            </Link>
          </List>
          <Content>
            <Switch>
              <Route path="/demo/text" component={Text} />
              <Route path="/demo/scan" component={Scan} />
              <Route path="/demo/article" component={Article} />
              <Route path="/demo/video" component={Video} />
              <Route path="/demo/options" component={Options} />
            </Switch>
          </Content>
        </ContentContainer>
      </Outer>
      <Footer />
    </>
  );
};

export default Demo;
