import React from "react";
import {
  Paragraph,
  Container,
  SignupDescription,
  Button,
  Input,
  Heading,
} from "./styles";

function Signup() {
  return (
    <Container>
      <div className="nine columns main-col">
        <SignupDescription>
          <div>
            <h1>Sign up</h1>
            <Paragraph>
              Want to receive an update when things are ready to go? Want early
              access to new summarizing features? Sign up now to stay in the
              loop.
            </Paragraph>
          </div>
          <div style={{ paddingTop: "10" }}>
            <Heading>Name</Heading>
            <Input placeholder="firstname lastname" />
            <Heading>Email</Heading>
            <Input placeholder="email" />
            <Button>Coming Soon!</Button>
          </div>
        </SignupDescription>
      </div>
    </Container>
  );
}

export default Signup;
