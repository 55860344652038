import React from "react";
import { IconContext } from "react-icons";
import { BiComment, BiHeart } from "react-icons/bi";
import {
  ArticleContainer,
  Title,
  Abstract,
  Bar,
  ReadMore,
  Button,
  Icons,
  Buttons,
  Count,
} from "./styles";

const ArticleCard = ({ title, abstract }) => (
  <ArticleContainer>
    <Title>{title}</Title>
    <Abstract>{abstract}</Abstract>
    <Bar>
      <Buttons>
        <ReadMore href="#">Read More...</ReadMore>
        <Button>Summarize</Button>
      </Buttons>
      <Icons>
        <IconContext.Provider
          value={{
            size: "1.5em",
            color: "rgb(135, 142, 149)",
            style: {
              verticalAlign: "middle",
              transition: "0.2s",
            },
          }}
        >
          <BiHeart />
        </IconContext.Provider>
        <Count>9</Count>
        <IconContext.Provider
          value={{
            size: "1.5em",
            color: "rgb(135, 142, 149)",
            style: {
              verticalAlign: "middle",
              transition: "0.2s",
            },
          }}
        >
          <BiComment />
        </IconContext.Provider>
        <Count>10</Count>
      </Icons>
    </Bar>
  </ArticleContainer>
);

export default ArticleCard;
