import React, { useState, useEffect, useContext } from "react";
import DashboardNavbar from "./components/DashboardNavbar";
import Sidebar from "./components/Sidebar";
import { Route, Switch } from "react-router-dom";
import { Container } from "./styles";
import Home from "./pages/home";
import Shared from "./pages/shared";
import { FirebaseContext } from "../../firebaseContext";

const Dashboard = () => {
  const [tab, setTab] = useState("feed");
  const { db } = useContext(FirebaseContext);
  useEffect(() => {
    if (db) {
      db.collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
          });
        });
    }
  }, [db]);

  return (
    <>
      <DashboardNavbar />
      <Container>
        <Sidebar setTab={setTab} />
        <Switch>
          <Route path="/dashboard/feed">
            <Home />
          </Route>

          <Route path="/dashboard/shared">
            <Shared />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default Dashboard;
