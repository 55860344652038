import React from "react";
// ,{ useState, useEffect } from 'react';
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { MetaContainer, Container } from "./styles";
import Home from "./pages/Home";
import About from "./pages/About";
import Demo from "./pages/Demo";

import Team from "./pages/Team";
import Product from "./pages/Product";
import Signup from "./pages/Signup";
import Login from "./pages/Login";

import Dashboard from "./pages/Dashboard";
import { FirebaseProvider } from "./firebaseContext";

// import { API } from  'aws-amplify';

function App() {
  return (
    <>
      <FirebaseProvider>
        <MetaContainer>
          <Container>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/demo">
                <Demo />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/product">
                <Product />
              </Route>
              <Route path="/team">
                <Team />
              </Route>
              <Route path="/signup">
                <Signup />
              </Route>
              <Route path="/login" component={Login}>
                <Login />
              </Route>
              <Route path="/dashboard" component={Dashboard}>
                <Dashboard />
              </Route>
            </Switch>
          </Container>
        </MetaContainer>
      </FirebaseProvider>
    </>
  );
}

export default App;
