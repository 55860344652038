import styled from 'styled-components';

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #ef8354;
  border: none;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  height: 40px;
  font-weight: 600;
`;

export const TextArea = styled.textarea`
  height: 75%;
  rows: 25;
  cols: 60;
  placeholder: "Type or Paste in Text... "
`;

export const Container = styled.div`
    width: 90%;
    margin: auto;
    justify-content: left;
    margin-bottom: 20px;
    display: flex;
`;
