import React, { useState, } from 'react';
import { Button, Container, Input, TextArea } from './styles'


const startText = `https://www.youtube.com/watch?v=3mnSDifDSxQ`


function Video() {


  const [articleURL, setArticleURL] = useState(startText);
  const [summaryText, setSummaryText] = useState("");

  const summarizeText = async text => {
    //const url = 'https://api.smrzr.io/summarize?ratio=0.15'
    const settings = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(text)
    };

    const response = await fetch(process.env.REACT_APP_INBRIEF_API + '/get_video', settings);
    const summary = await response.json();
    setSummaryText(summary.output);
  }


    return (
        <div>

          <Container>
            <p>
              Coming soon!
            </p>
          </Container>
          <Container>
            <Input name='text' cols="120" placeholder="Type or Paste Text..." value={articleURL} onChange={event => setArticleURL(event.currentTarget.value)}></Input>
            <Button onClick={() => summarizeText(articleURL)}>
               Summarize
            </Button>
          </Container>
          <Container>
            <TextArea rows="25" cols="60" placeholder="Output..." value={summaryText} readOnly></TextArea>
          </Container>
        </div>
    );
}

export default Video
