import React, { useState } from "react";
import {
  Container,
  FeedContainer,
  ContainerLeft,
  Header,
  ContainerRight,
} from "./styles";
import { Route, Switch } from "react-router-dom";
import ProjectCard from "./components/ProjectCard";
import ProjectDetails from "./components/ProjectDetails";
import img from "./components/ProjectCard/document.png";
import img2 from "./components/ProjectCard/document2.png";
import Gabe from "../../../../images/headshot.PNG";
import FeedView from "./components/FeedView";
import SourcesView from "./components/SourcesView";
const Shared = () => {
  const [project, setProject] = useState({});

  return (
    <Container>
      <ContainerLeft>
        <Header>
          <h3>Shared Projects</h3>
        </Header>
        <Switch>
          <Route path="/dashboard/shared/feed/:title">
            <FeedView />
          </Route>
          <Route path="/dashboard/shared/sources/:title">
            <SourcesView />
          </Route>
          <Route path="/dashboard/shared">
            <FeedContainer>
              <ProjectCard
                selected={project}
                src={img}
                setProject={setProject}
                project={{
                  title: "Computer Science and ML",
                  authors: "Steve, Gabe, Will",
                  description:
                    "this project is about computer science and machien learning, specifically in the field of GAN and SHARONGANS",
                  numSources: 5,
                }}
              />
              <ProjectCard
                selected={project}
                src={img}
                setProject={setProject}
                project={{
                  title: "Computer engineering and lots of other shit",
                  authors: "Steve, Leonard, Ray",
                  description:
                    "this project is about computer engineering and machien learning, specifically in the field of GAN and lots of other shit",
                  numSources: 5,
                }}
              />
              <ProjectCard
                selected={project}
                src={img2}
                setProject={setProject}
                project={{
                  title: "CSL",
                  authors: "Steve, Gabe, Will",
                  description:
                    "this project is about computer science and machien learning, specifically in the field of GAN and SHARONGANS",
                  numSources: 5,
                }}
              />
              <ProjectCard
                selected={project}
                src={Gabe}
                setProject={setProject}
                project={{
                  title: "Computers",
                  authors: "Steve, Gabe, Will",
                  description:
                    "this project is about computer science and machien learning, specifically in the field of GAN and SHARONGANS",
                  numSources: 5,
                }}
              />
            </FeedContainer>
          </Route>
        </Switch>
      </ContainerLeft>
      <ContainerRight>
        <Header>
          <h3>Details</h3>
        </Header>
        <ProjectDetails project={project} />
      </ContainerRight>
    </Container>
  );
};

export default Shared;
