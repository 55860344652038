import styled from "styled-components";
import { Colors } from "../../../../../../styles/index";

export const Container = styled.div`
  width: 100%;
  & h2 {
    font-weight: 500;
  }
  & h4 {
    margin: 0px;
    font-weight: 500;
  }

  & p {
    margin-top: 0.5em;
    font-size: 12px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.5em;
`;
export const DetailButton = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.active ? `${Colors.highlight1}` : `white`};
  margin-right: 0.5em;
  border: ${(props) =>
    props.active
      ? `2px solid ${Colors.highlight1}`
      : `2px solid ${Colors.primary}`};

  color: ${(props) => (props.active ? `#ffffff` : `${Colors.primary}`)};

  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-weight: 600;
  transition: 0.2s;

  &:hover {
    color: #ffffff;
    background-color: ${Colors.highlight1};
    cursor: pointer;
    border: 2px solid ${Colors.highlight1};
  }
`;
