import styled from "styled-components";
import { device, Colors } from "../../../../styles/index";

export const Container = styled.div`
  @media ${device.mobileS} {
    width: calc(100% - 105px);
    left: 105px;
  }
  @media ${device.laptop} {
    left: 250px;
    width: calc(100% - 250px);
  }
  position: absolute;
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const ContainerLeft = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  & h3 {
    margin: 0px;
    font-weight: 500;
  }

  border-bottom: 1px solid ${Colors.highlight4};
  margin-bottom: 1em;
  padding-bottom: 0.5em;
`;

export const FeedContainer = styled.div`
  height: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: grid;
  grid-gap: 1em;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${device.laptopL} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ContainerRight = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
`;
