import styled from "styled-components";
import { device } from "../../../../styles/index";

export const Container = styled.div`
  @media ${device.mobileS} {
    width: calc(100% - 105px);
    left: 105px;
  }
  @media ${device.laptop} {
    left: 250px;
    width: calc(100% - 250px);
  }
  position: absolute;
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const FeedContainer = styled.div`
  height: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: grid;
  column-gap: 1em;
`;

export const StatsContainer = styled.div`
  height: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
  display: grid;
  column-gap: 1em;
`;
