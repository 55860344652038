import styled from "styled-components";
import { device } from "../../styles/index";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 50px;
  display: flex;
  z-index: 5;
  position: relative;
`;

export const FeedContainer = styled.div`
  @media ${device.mobileS} {
    width: calc(100% - 105px);
    left: 105px;
  }
  @media ${device.laptop} {
    left: 250px;
    width: calc(100% - 250px);
  }
  position: absolute;

  height: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
`;

export const PdfContainer = styled.div`
  @media ${device.mobileS} {
    width: calc(100% - 105px);
    left: 105px;
  }
  @media ${device.laptop} {
    left: 250px;
    width: calc(100% - 250px);
  }
  position: absolute;
`;
