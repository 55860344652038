import styled from "styled-components";
import { Colors } from "../../../../../../styles/index";

export const ColumnContainer = styled.div`
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1em 1em 1em;
  //   border: 1px solid ${Colors.highlight4};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Heading = styled.div`
  & h4 {
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
  }
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1em;
  //   background-color: ${Colors.highlight4};
  //   border-top: 1px solid ${Colors.highlight4};
  //   border-left: 1px solid ${Colors.highlight4};
  //   border-right: 1px solid ${Colors.highlight4};
`;

export const Filters = styled.div`
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em 1em 0 1em;
  display: flex;
`;

export const Filter = styled.p`
  color: ${Colors.primary};
  font-size: 13px;
  font-weight: 600;
  margin: 0 0.8em 0 0;
  padding: 1em;
  transition: 0.2s;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? Colors.highlight4 : "none")};

  &:hover {
    background-color: ${Colors.highlight4};
    cursor: pointer;
  }
`;
