import styled from "styled-components";
import { Colors, device } from "../../../../styles/index";

export const SidebarContainer = styled.div`
  height: 100vh;
  position: fixed;
  background-color: white;
  //   box-shadow: 0 0 1.25rem rgba(31,45,61,0.3);
  padding-top: 1.2rem;
  border-right: 1px solid #eaecee;
  z-index: 20;
  transition: 0.2s;
  @media ${device.mobileS} {
    width: 105px;
  }
  @media ${device.laptop} {
    width: 250px;
  }
`;

export const ListItem = styled.div`
  color: ${Colors.primary};
  padding-left: 1.5rem;
  margin: 0px;
  transition: 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
`;

export const ListIcon = styled.div`
  display: flex;
  align-items: center;
  transition: 0.2s;
  padding-left: 1rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const Item = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0 1em;
  color: ${Colors.primary};

  transition: 0.2s;
  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.laptop} {
    display: block;
    color: ${Colors.primary};
  }
`;
