import React from "react";
import { Link } from "react-router-dom";
import { Container, Paragraph } from "./styles";
import summary1 from "../../images/summary1.PNG";
import summary2 from "../../images/summary2.PNG";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function About() {
  return (
    <>
      <Navbar background />

      <Container>
        <div
          className="nine columns main-col"
          style={{ width: "100%", minHeight: "75vh", maxWidth: "1024px" }}
        >
          <h2>About</h2>
          <Paragraph>
            {" "}
            The availability of information is now greater than ever with the
            internet. Webinars and free lecture series have made learning new
            and front line research drastically easier. Despite this, many of us
            still find ourselves with a shortage of time or motivation to be
            watching long, educational videos, or to read through boring
            articles or research papers. As the demand for interesting yet
            concise information rises, natural language processing for
            information aggregation will become one of the most powerful tools
            available to curious learners and researchers.{" "}
            <b>The nBrief idea - to make use of web scraping techniques</b> in
            conjunction with the{" "}
            <b>most recent text summarization machine learning frameworks</b> to
            allow for <b> ultimate information absorbing pace</b>.
          </Paragraph>

          <br />

          <Paragraph>
            {" "}
            This project is currently in development. Begun in September 2020,
            the goal is to create an educational tool to promote research
            through the internet. There are 4 basic functions: text
            summarization, article summarization, video summarization, and
            scanned input summarization. The beta site will feature these 4
            functions in some capacity <b> by the end of 2020 </b>. Looking
            forward, the <Link to="/team">team</Link> hopes to develop more
            advanced algorithms. Ideas include but are not limited to:
            generating dynamic summaries; text-mining analysis for commonly used
            words, phrases, or concepts; creating infographics; stitching
            together summaries of videos in video form; recommending other
            papers or sites based off of searched sources; and much more.
          </Paragraph>

          <br />

          <Paragraph>
            {" "}
            <Link to="/team">Steve, Gabe, and Will</Link> met at the Harvard
            Freshman Outdoors Program in August 2019. The members have been good
            friends since and are excited to be working on this project
            together. The team is dedicated to creating an intuitive and
            user-friendly interface to facilitate research for everyone, and in
            particular those who are passionate and curious but may not have the
            longest attention spans (anyone? no? ok maybe just us.) Enjoy
            playing around with the <Link to="/demo">tool</Link> in its current
            state!
          </Paragraph>

          <br />

          <h2>What is a Summary? </h2>

          <Paragraph> Well, according to Google... </Paragraph>

          <img src={summary1} alt="summary1" />
          <img src={summary2} alt="summary2" />
          <br />

          <Paragraph>
            {" "}
            Looks right to us. Most people have heard of regular old summaries.
            A dynamic summary though ...{" "}
          </Paragraph>

          <br />

          <h2>
            {" "}
            What is a <i>Dynamic</i> Summary?{" "}
          </h2>

          <Paragraph>
            {" "}
            Dynamic summaries don't quite have a universal definition yet.
            That's why we're assigning one. <Link to="/">In brief, </Link>a
            dynamic summary is{" "}
            <b>
              an architecture through which one can interact with to learn
              information in a quick and streamlined fashion.{" "}
            </b>
            On the other hand, a typical summary is static. It sits there,
            waiting to be read, like regular text. The goals of dynamic
            summaries are twofold: (1) to allow readers to more fully understand
            a source in a shorter amount of time, and (2) to lead the reader in
            other directions of potential research interest.
          </Paragraph>

          <br />

          <h2> The Algorithms </h2>
          <Paragraph>
            {" "}
            We currently only use extractive summarization techniques, but
            abstractive techniques may be implemented in the near future.{" "}
          </Paragraph>
          <br />
          <h3>Text-Rank</h3>
          <Paragraph>Detailed description coming soon.</Paragraph>
          <br />

          <h3>Luhn</h3>
          <Paragraph>Detailed description coming soon.</Paragraph>
          <br />

          <h3>Lex-Rank</h3>
          <Paragraph>Detailed description coming soon.</Paragraph>
          <br />

          <h3>LSA</h3>
          <Paragraph>Detailed description coming soon.</Paragraph>
          <br />

          <h2> Sign Up </h2>
          <Paragraph>
            {" "}
            <Link to="/signup"> Sign up now</Link> to receive updates on
            nBrief's new releases!
          </Paragraph>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default About;
