const CONSTANTS = {
  details: {
    student:
      "Create projects and start researching using our extensive search API, creating dynamic summaries and annotations for your next assignment. View video webinars and lecture videos to save time on taking notes and gathering information.",
    researcher:
      "Share sources and insights on papers, discuss new ideas, and collaborate on larger projects, staying up to date with your colleagues latest findings. Like and comment important papers, articles, and other sources.",
    learner:
      "Start learning by saving annotations and machine-learning based summaries on any topic of your choosing. Learn from a like-minded community of scholars by joining communities related to your subject. ",
  },
};

export default CONSTANTS;
