import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Links,
  NavItem,
  NavbarContainer,
  NavbarInner,
  Brand,
  Hamburger,
  Bar,
} from "./styles";

const Navbar = ({ background }) => {
  const [slide, toggleSlide] = useState(false);
  return (
    <NavbarContainer>
      <NavbarInner>
        <Link to="/" style={{ textDecoration: "none" }}>
          {" "}
          <Brand background={background}>nBrief</Brand>
        </Link>
        <Links>
          <Link to="/demo">
            <NavItem background={background}>demo</NavItem>
          </Link>
          <Link to="/about">
            <NavItem background={background}>about</NavItem>
          </Link>
          <Link to="/product">
            <NavItem background={background}>product</NavItem>
          </Link>
          <Link to="/team">
            <NavItem background={background}>team</NavItem>
          </Link>
          <Link to="/login">
            <Button background={background} outline>
              log in
            </Button>
          </Link>
        </Links>
        <Hamburger slide={slide} onClick={() => toggleSlide(!slide)}>
          <Bar
            num={0}
            rotate1={slide && true}
            slide={slide}
            style={{ position: "relative", zIndex: 2 }}
          />
          <Bar
            num={2}
            rotate2={slide && true}
            slide={slide}
            style={{ position: "relative", zIndex: 3 }}
          />
          <Bar
            num={1}
            rotate3={slide && true}
            slide={slide}
            style={{ position: "relative", zIndex: 2 }}
          />
        </Hamburger>
      </NavbarInner>
    </NavbarContainer>
  );
};

export default Navbar;
