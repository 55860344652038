import React from "react";
import { ColumnContainer, Filters, Filter } from "./styles";
import ArticleCard from "../../../../components/ArticleCard";
import articles from "./articles";
const ColumnFeed = ({ heading }) => (
  <div>
    <Filters>
      <Filter active={true}>All</Filter>
      <Filter>News Articles</Filter>
      <Filter>Trending</Filter>
      <Filter>More Filters...</Filter>
    </Filters>
    <ColumnContainer>
      {articles.map((article, index) => (
        <ArticleCard
          key={index}
          title={article.title}
          abstract={article.abstract}
        />
      ))}
    </ColumnContainer>
  </div>
);

export default ColumnFeed;
