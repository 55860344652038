import styled from "styled-components";
import { Colors } from "../../../../../../styles/index";

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${Colors.highlight4};
  width: 100%;
  height: 180px;

  // &:before {
  //   content: "";
  //   padding-bottom: 100%;
  //   display: inline-block;
  //   vertical-align: top;
  // }

  & img {
    width: 100%;
    height: auto;

    max-height: 130px;
    object-fit: cover;
  }

  display: flex;
  flex-direction: column;
  min-width: 0;

  justify-content: space-bewteen;
`;

export const Title = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  width: calc(100%);
  height: 50px;
  border-top: 1px solid ${Colors.highlight4};
  background-color: ${(props) => (props.active ? Colors.highlight5 : "white")};
  display: flex;
  transition: 0.2s;

  & p {
    font-size: 14px;
    transition: 0.2s;

    display: inline-block;
    vertical-align: center;
    width: calc(100%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1em 1em;
    color: ${(props) => (props.active ? Colors.primary : "black")};
    font-weight: ${(props) => (props.active ? 600 : 400)};
  }
`;
