import styled from "styled-components";
import { device, Colors } from "../../styles/index";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 100000;
`;

export const NavbarInner = styled.div`
  @media ${device.mobileS} {
    width: 80%;
  }
  @media ${device.tabletL} {
    width: 100%;
  }
  max-width: 1024px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Brand = styled.h2`
  // border-bottom: 2px solid white;
  transition: 0.2s;
  text-decoration: none;
  color: #ffffff;
  color: ${(props) => (props.background ? `${Colors.primary}` : "#ffffff")};
`;

export const Hamburger = styled.div`
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media ${device.mobileS} {
    display: block;
  }
  @media ${device.laptop} {
    display: none;
  }

  height: 30px;

  transform: ${(props) => (props.slide ? "translateX(-40vw)" : null)};
`;

export const Bar = styled.p`
  border-radius: 25px;
  height: 3px;
  background-color: ${Colors.highlight1};
  margin: 6px 0;
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: ${(props) =>
    props.rotate1 ? "translateY(9px) rotate(-45deg)" : null};
  transform: ${(props) => (props.rotate2 ? "rotate(45deg)" : null)};
  transform: ${(props) =>
    props.rotate3 ? "translateY(-9px) rotate(45deg)" : null};
  width: ${(props) => (props.slide ? "30px" : `${30 - props.num * 7}px`)};
  /* width: 30px;  */
`;

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.outline ? "transparent" : `${Colors.highlight1}`};
  margin-left: 20px;
  border: ${(props) =>
    props.outline ? `2px solid ${Colors.highlight1}` : "none"};

  color: ${(props) =>
    props.outline || props.background ? `${Colors.highlight1}` : "#ffffff"};

  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  font-weight: 600;
  transition: 0.2s;

  &:hover {
    color: ${(props) => (props.outline ? "#ffffff" : "#ffffff")};
    background-color: ${(props) =>
      props.outline ? `${Colors.highlight1}` : `${Colors.highlight1}`};
    cursor: pointer;
  }
`;

export const NavItem = styled.p`
  font-size: 16px;
  position: relative;
  margin: 0px;
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.2s;
  width: fit-content;
  text-align: center;
  color: ${(props) => (props.background ? `${Colors.primary}` : "#ffffff")};
  &::after {
    content: "";
    display: block;
    width: ${(props) => (props.filled ? "100%" : "0")};
    height: 3px;
    background: ${Colors.highlight1};
    transition: width 0.3s;
  }

  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
    //transition: width .3s;
  }

  &:hover a {
    color: rgba(31, 45, 61, 1);
  }
`;

export const Links = styled.div`
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.laptop} {
    display: flex;
    align-items: center;
  }

  & a {
    color: #ffffff;
    font-size: 1.2rem;
    text-decoration: none;
    transition: 0.2s;
    font-weight: 600;
  }

  & a:visited {
    color: black;
  }
`;
