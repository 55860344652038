import styled from 'styled-components';
import { Colors } from '../../styles/index';

export const Container = styled.div`
    min-height: 70vh; 
    width: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
`;

export const Toggle = styled.div`
    width: 100%; 
    display: flex;
    justify-content: space-evenly; 
    margin-bottom: 2em; 
`;
export const ToggleOption = styled.p`
    font-size: 0.9rem; 
    font-weight: 600; 
    transition: .2s;

    color: ${(props) => (props.filled ? `${Colors.highlight1}` : 'rgb(147, 147, 147)')};

    &::after {
        content: '';
        display: block;
        width: ${(props) => (props.filled ? '100%' : '0')};
        height: 3px;
        background: ${Colors.highlight1};
        transition: width .3s;
    }
    
    &:hover {
        cursor: pointer; 
    }
    &:hover::after {
        width: 100%;
        //transition: width .3s;
    }
`;

export const Inner = styled.div`
    width: 80%; 
    max-width: 400px; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    padding: 3em 2em; 
    box-shadow: 0 0 10px hsla(0,0%,62.7%,.3); 
    border-radius: 5px; 
`;

export const Input = styled.input`
  width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box; 
  margin-bottom: 10px; 
  background-color: rgb(245, 246, 247);
  padding: 0.5rem 0rem 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8492a6;
  transition: 0.2s;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  border-radius: 0.25rem;
  font-family: 'Inter', sans-serif;


  &:focus {
    outline: 0;
    border-color: ${Colors.highlight1};
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
  }
`;

export const Inputs = styled.div`
  width: 100%; 
  margin-bottom: 1.5em; 
`;
export const Button = styled.button`
    width: 100%; 
    padding: 8px 20px;
    border-radius: 4px;
    background-color: ${Colors.secondary};
    border: none;
    color: #ffffff;
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    height: 40px;
    font-weight: 600;
    margin-bottom: 1.5em; 

`;

export const Link = styled.a`
  font-size: 0.9rem; 
`;

export const FormInner = styled.div`
  width: 100%; 
  display: flex; 
  align-items: center; 
  flex-direction: column; 
`;
