import React from "react";
import ColumnFeed from "./components/ColumnFeed";
import { FeedContainer, StatsContainer, Container } from "./styles";
import Stats from "./components/Stats";
const Home = () => {
  return (
    <Container>
      <FeedContainer>
        <ColumnFeed heading="physics" />
      </FeedContainer>
      <StatsContainer>
        <Stats />
      </StatsContainer>
    </Container>
  );
};

export default Home;
