/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1b767d3b-ede4-4f27-8f7c-9e44cf525354",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_sxMOKAW6d",
    "aws_user_pools_web_client_id": "15pn1bku96373e87ib1plo2fta",
    "oauth": {}
};


export default awsmobile;
