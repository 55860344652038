import React from "react";
import { Container, Heading, Row, Title, Description } from "./styles";
const Statistic = ({ heading }) => {
  return (
    <Container>
      <Heading>
        <h4>{heading}</h4>
      </Heading>
      <Row>
        <Title>Resaerch paper about physics blah blah </Title>
        <Description>This is about etc. </Description>
      </Row>
      <Row>
        <Title>Resaerch paper about biology and chemistry blah blah </Title>
        <Description>This is about etc. </Description>
      </Row>
      <Row>
        <Title>Resaerch paper about computer science blah blah </Title>
        <Description>This is about etc. </Description>
      </Row>
    </Container>
  );
};

export default Statistic;
