import React, { useState, } from 'react';
import { TextArea } from '../Video/styles'
import { Container, Button } from './styles'
import axios from 'axios';
import Spinner from '../../components/Spinner'
import '../../global.js'


function Scan() {

  const [loading, setLoading] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const [summaryText, setSummaryText] = useState("");


  const onChangeFile = e => {
      setInputFile(e.target.files[0]);
  };


  const summarizeFile = async file => {

      if (file)
        setLoading(true);

      const formData = new FormData();
      formData.append("file", inputFile);
      formData.append("num_sentences", global.summaryLength)
      formData.append("algorithm",  global.algorithm)

      axios({
          method: 'post',
          url: process.env.REACT_APP_INBRIEF_API + '/get_scan',
          // url: '/get_scan',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
          .then((response) => {
              setLoading(false)
              setSummaryText(response.data.output)
          })
          .catch(errors => console.log(errors))
      }

    return (
        <div>

          <Container>
            <p>
              Enter your file below and click "Summarize" to see a quick summarization! Currently supported file types include PDFs, PNGs, JPGs, and JPEGs. This demo
              makes a quick call to a backend API which implements a user-chosen method to summarize the text. Dynamic summaries to come soon.
            </p>
          </Container>
          <br />
          <Container>

            <Button onClick={() => summarizeFile(inputFile)}>
              Summarize
            </Button>
            <input type="file" name="file" onChange={onChangeFile}/>
            {loading ? <Spinner /> : <br />}



          </Container>
          <br />
          <Container>
            <TextArea rows="25" cols="60" placeholder="Output..." value={summaryText} readOnly></TextArea>
          </Container>
        </div>
  );
}

export default Scan
