import React, { useState, useEffect } from "react";
import { firebaseApp } from "./firebase";
export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
  const [db, setdb] = useState(null);

  const createFirestore = () => {
    setdb(firebaseApp.firestore());
  };

  useEffect(() => {
    createFirestore();
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        db,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
