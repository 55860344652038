import styled from "styled-components";
import { Colors } from "../../../../styles/index";

export const NavbarContainer = styled.nav`
  // background-color: ${Colors.primary};
  background-color: white;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 50px;
  // box-shadow: 0 0 1.25rem rgba(31,45,61,0.4);
  border-bottom: 1px solid ${Colors.highlight4};
  display: flex;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  justify-content: space-between;
  align-items: center;

  & img {
    display: block;
    width: 3.5em;
    height: auto;
    cursor: pointer;
  }
  z-index: 100000000;
`;

export const SearchContainer = styled.div`
  height: auto;
  position: relative;
  width: 60%;
  box-shadow: ${(props) =>
    props.focus ? "0 0 0.7rem rgba(31, 45, 61, 0.1)" : "none"};
`;

export const Search = styled.input`
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 5px 0 5px 1rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: ${Colors.primary};
  transition: 0.2s;
  box-shadow: none;
  border: none;
  border-radius: 0.25rem;
  font-family: "Inter", sans-serif;
  // border: 1px solid ${Colors.highlight4};
  border-bottom: ${(props) =>
    props.active
      ? `border-bottom: 2px solid ${Colors.highlight4} `
      : "2px solid white"};

  outline: ${(props) => (props.active ? 1 : 0)};
  background-color: ${(props) =>
    props.active ? "white" : `${Colors.highlight4}`};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const Img = styled.img`
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  border: 2px solid ${Colors.highlight1};
`;

export const SearchDetails = styled.div`
  width: 100%;
  padding: 1rem;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  margin-top: 27px;
  background-color: white;
  box-shadow: 0 0 0.7rem rgba(31, 45, 61, 0.1);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  & p {
    margin: 0px;
    color: gray;
    font-size: 12px;
  }
`;

export const SearchButtons = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const Button = styled.button`
  padding: 7px 15px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.outline ? "transparent" : `${Colors.secondary}`};
  margin-right: 10px;
  border: ${(props) =>
    props.outline ? `2px solid ${Colors.highlight1}` : "none"};

  color: ${(props) =>
    props.outline || props.background ? `${Colors.highlight1}` : "#ffffff"};
  // color: ${(props) =>
    props.outline ? `${Colors.highlight1}` : `${Colors.highlight1}`};

  font-size: 14px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-weight: 550;
  transition: 0.2s;

  &:hover {
    color: ${(props) => (props.outline ? "#ffffff" : "#ffffff")};
    background-color: ${(props) =>
      props.outline ? `${Colors.highlight1}` : `${Colors.highlight1}`};
    cursor: pointer;
  }
`;
