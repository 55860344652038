import styled from "styled-components";
import { device, Colors } from "../../styles/index";

export const Container = styled.div`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 75vh;
  width: 100%;
  // background: rgb(36,48,94);
  // background: linear-gradient(90deg, rgba(36,48,94,1) 0%, rgba(55,71,133,1) 35%, rgba(84,187,244,1) 100%);
  display: flex;
  justify-content: center;
  position: relative;
`;

export const BackgroundContainer = styled.div`
  background: rgb(36, 48, 94);
  background: linear-gradient(
    41deg,
    rgba(36, 48, 94, 1) 0%,
    rgba(55, 71, 133, 1) 35%,
    rgba(38, 196, 133, 1) 100%
  );
  transform: skewY(-8deg) translateY(-30vh);
  @media ${device.mobileS} {
    transform: skewY(-15deg) translateY(-40vh);
  }
  @media ${device.tabletL} {
    transform: skewY(-15deg) translateY(-60vh);
  }
  width: 100vw;
  height: 110vh;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Box = styled.div`
  background: ${(props) => props.color};
  width: ${(props) => props.width};
  left: ${(props) => props.left};
  height: ${(props) => props.height};
  position: absolute;
  bottom: ${(props) => ` ${-5 * props.index}vh`};
  top: ${(props) => props.top};
`;

export const AboutDescription = styled.div`
  position: relative;
  display: grid;
  max-width: 1024px;
  @media ${device.mobileS} {
    padding: 0px 0px;

    width: 80%;
  }

  @media ${device.tabletL} {
    padding: 120px 0px;

    width: 100%;
  }
  backgroundcolor: "green";
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  align-content: center;
`;

export const Header1 = styled.h1`
  color: ${(props) => props.color};
`;
export const Header2 = styled.h2`
  color: ${(props) => props.color};
`;

export const Details = styled.p`
  margin: 2rem 0;
  color: ${Colors.highlight3};
  line-height: 1.5715;
`;

export const HeaderImage = styled.img`
  width: 100%;
  max-width: 450px;
  height: auto;
  align-self: center;
  justify-self: center;
`;

export const Button = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: ${Colors.highlight1};
  border: none;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  font-weight: 600;

  & a {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const DetailsDescription = styled.div`
  position: relative;
  display: grid;
  max-width: 1024px;
  @media ${device.mobileS} {
    padding: 0px 0px;

    width: 80%;
  }

  @media ${device.tabletL} {
    width: 100%;
  }
  backgroundcolor: "green";
  grid-template-columns: 1.1fr 2fr;
  justify-content: center;
  align-items: center;
`;

export const DetailsLeft = styled.div`
  & p {
    line-height: 1.7;
  }
`;

export const DetailsRight = styled.div`
  padding: 2em;
`;

export const Buttons = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-bottom: 1.5em;
`;
export const DetailButton = styled.button`
  padding: 8px 20px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.active ? `${Colors.highlight1}` : `white`};
  margin-right: 20px;
  border: ${(props) =>
    props.active
      ? `2px solid ${Colors.highlight1}`
      : `2px solid ${Colors.primary}`};

  color: ${(props) => (props.active ? `#ffffff` : `${Colors.primary}`)};

  font-size: 16px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  font-weight: 600;
  transition: 0.2s;

  &:hover {
    color: #ffffff;
    background-color: ${Colors.highlight1};
    cursor: pointer;
    border: 2px solid ${Colors.highlight1};
  }
`;

export const DetailImage = styled.img`
  width: 100%;
  height: auto;
`;
