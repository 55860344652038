import React, { useState } from "react";
import {
  NavbarContainer,
  Search,
  Img,
  SearchContainer,
  SearchDetails,
  SearchButtons,
  Button,
} from "./styles";
import Profile from "./profile.png";

const DashboardNavbar = () => {
  const [search, setSearch] = useState("");
  const [focus, setFocus] = useState(false);

  return (
    <NavbarContainer>
      <SearchContainer focus={focus}>
        <Search
          placeholder="Search papers, summaries, etc."
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setFocus(true)}
          active={focus}
        />
        {focus && (
          <SearchDetails onClick={() => setFocus(true)}>
            <p>I'm looking for...</p>
            <SearchButtons>
              <Button>Papers</Button>
              <Button>Projects</Button>
              <Button>People</Button>
            </SearchButtons>
            <p>Recent Searches</p>
          </SearchDetails>
        )}
      </SearchContainer>
      <Img src={Profile} />
    </NavbarContainer>
  );
};

export default DashboardNavbar;
