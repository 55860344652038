import React from "react";
import { Container, Heading } from "./styles";
import Statistic from "../Statistic";
const Stats = () => {
  return (
    <Container>
      <Statistic heading="Top Group Projects" />
      <Statistic heading="Top Individual Projects" />
      <Statistic heading="Top Casual Projects" />
      <Statistic heading="Recently Viewed" />
    </Container>
  );
};

export default Stats;
