import React from "react";
import { Container, Buttons, DetailButton } from "./styles";
import { Link } from "react-router-dom";

const ProjectDetails = ({ project }) => {
  return (
    <Container>
      {!project.title && <h4>Click on a project to view its details</h4>}
      {project.title && (
        <div>
          <h2>{project.title}</h2>
          <h4>Description</h4>
          <p>{project.description}</p>
          <h4>Authors</h4>
          <p>{project.authors}</p>
          <h4>Number of Sources</h4>
          <p>{project.numSources}</p>
          <h4>Last edited:</h4>
          <p>February 12, 2021 by me</p>
          <Buttons>
            <Link to={`/dashboard/shared/feed/${project.title}`}>
              <DetailButton>View Feed</DetailButton>
            </Link>
            <Link to={`/dashboard/shared/sources/:title`}>
              <DetailButton>View Sources</DetailButton>
            </Link>
          </Buttons>
        </div>
      )}
    </Container>
  );
};

export default ProjectDetails;
