import React from "react";
import { ColumnContainer } from "./styles";
import articles from "../../../home/components/ColumnFeed/articles";
import ArticleCard from "../../../../components/ArticleCard";
const FeedView = () => {
  return (
    <ColumnContainer>
      {articles.map((article, index) => (
        <ArticleCard
          key={index}
          title={article.title}
          abstract={article.abstract}
        />
      ))}
    </ColumnContainer>
  );
};

export default FeedView;
