import styled from "styled-components";
import { device, Colors } from "../../styles/index";

export const Row = styled.div`
  @media ${device.mobileS} {
    width: 80%;
  }

  @media ${device.tabletL} {
    width: 100%;
  }
  max-width: 1024px;

  & h2 {
    color: ${Colors.primary};
  }
  margin-bottom: 1em;
`;

export const Container = styled.div`
  @media ${device.mobileS} {
    width: 80%;
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${device.tabletL} {
    grid-template-columns: repeat(3, 1fr);

    width: 100%;
  }
  max-width: 1024px;
  min-height: 90vh;
  margin: auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
`;

export const Card = styled.div`
  width: 80%;
  min-height: 500px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
  padding: 2em;
  border-radius: 5px;

  p {
    margin-top: 0px;
  }

  h3 {
    margin-bottom: 0px;
  }
`;

export const Image = styled.img`
  width: 70%;
  max-width: 400px;
  height: auto;
  border-radius: 50%;
  border: 5px solid ${Colors.highlight1};
`;
export const Name = styled.h3`
  color: #555555;
  font-weight: 0 !important;
`;
export const Title = styled.p`
  color: rgba(31, 45, 61, 0.5);
`;

export const Bio = styled.p`
  color: rgba(43, 46, 50, 0.85);
`;
