import React from 'react';
import { Button, Container, Input } from './styles'
import '../../global.js'



function Options() {

  const algorithm = global.algorithm
  const summaryLength = global.summaryLength

  const applyChanges = async () => {
      global.algorithm = document.getElementById("2").value
      global.summaryLength = document.getElementById("1").value
    }

    return (
        <div>
          <Container>
            <h2>
              Change your summary length and summarizing algorithm here!
            </h2>
          </Container>
          <Container>
            <Input id='1' name='text' cols="120"  defaultValue={summaryLength} ></Input>
            <select id='2' defaultValue={algorithm} name="algorithm">
              <option value="TextRankSummarizer">Text-Rank Summarizer</option>
              <option value="LSA Summarizer">LSA Summarizer</option>
              <option value="LexRankSummarizer">Lex-Rank Summarizer</option>
              <option value="LuhnSummarizer">LuhnSummarizer</option>
            </select>
          </Container>
          <Container>
            <p>More information about the different summarizers can be found on the about page.</p>
          </Container>
          <Container>
            <Button onClick={() => applyChanges()}>
               Apply Changes!
            </Button>
          </Container>
        </div>
    );
}

export default Options
