import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Colors } from "../../styles/index";
import {
  Button,
  Details,
  Header1,
  Header2,
  HeaderImage,
  AboutDescription,
  Container,
  BackgroundContainer,
  Box,
  DetailsDescription,
  DetailsLeft,
  DetailsRight,
  Buttons,
  DetailButton,
  DetailImage,
} from "./styles";
import CONSTANTS from "./constants/index";
import img from "../../images/notes.svg";
import Navbar from "../../components/Navbar";

import StudentView from "../../images/student-view.svg";

const Home = () => {
  const [active, setActive] = useState("student");
  return (
    <>
      <Navbar background={false} />

      <Container>
        <BackgroundContainer>
          <Box
            width="60vw"
            color={Colors.secondary}
            index={0}
            left={0}
            height="20vh"
          />
          <Box
            width="40vw"
            color="#355985"
            index={0}
            left={0}
            top={0}
            height="45vh"
          />
        </BackgroundContainer>
        <AboutDescription>
          <div>
            <Header1 color={Colors.highlight3}>
              create dynamic summaries like never before.
            </Header1>
            <Details>
              Reclaim time and efficiency on research. Get setup and start
              learning in minutes.
            </Details>
            <Button>
              <Link to="/about">Learn More</Link>
            </Button>
          </div>
          <HeaderImage src={img} />
        </AboutDescription>
      </Container>
      <Container>
        <DetailsDescription>
          <DetailsLeft>
            <Header2 color={Colors.primary}>
              Conduct research as a{" "}
              <span style={{ color: Colors.highlight1 }}>{active}</span>
            </Header2>
            <p>{CONSTANTS.details[active]}</p>
          </DetailsLeft>
          <DetailsRight>
            <Buttons>
              <DetailButton
                active={active === "student"}
                onClick={() => setActive("student")}
              >
                Student
              </DetailButton>
              <DetailButton
                active={active === "researcher"}
                onClick={() => setActive("researcher")}
              >
                Researcher
              </DetailButton>
              <DetailButton
                active={active === "learner"}
                onClick={() => setActive("learner")}
              >
                Learner
              </DetailButton>
            </Buttons>
            <DetailImage src={StudentView} />
            {/* <Carousel active={active} setActive={setActive} /> */}
          </DetailsRight>
        </DetailsDescription>
      </Container>
    </>
  );
};

export default Home;
