import React from "react";
import { Container, Image, Card, Name, Bio, Title, Row } from "./styles";
import Will from "./will.png";
import Steve from "./steve.jpg";
import Gabe from "./gabe.jpg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function Team() {
  return (
    <>
      <Navbar background />
      <Row>
        <h2>About us</h2>
        <p>
          We're a cambridge-based team of engineers, researchers, and students.
        </p>
      </Row>
      <div>
        <Container>
          <Card>
            <Image src={Will} />
            <Name>Will C.</Name>
            <Title>Co-Founder</Title>
            <Bio>
              Will Cooper is an undergraduate at Harvard University studying
              applied mathematics and computer science. At school, he is
              involved in Harvard Computer Society, the Memorial Church Choir,
              and loves playing club hockey and club tennis. In his free time,
              he loves hanging out and going to new places.
            </Bio>
          </Card>
          <Card>
            <Image src={Steve} />
            <Name>Steve L.</Name>
            <Title>Co-Founder</Title>
            <Bio>
              Steve is a computer science student at Harvard University.
              Originally hailing from Carlisle, MA, he takes pictures for the
              Crimson, plays oboe for the Harvard Radcliffe Orchestra, and is a
              consultant for the Harvard Data Analytics Group.
            </Bio>
          </Card>
          <Card>
            <Image src={Gabe} />
            <Name>Gabe K.</Name>
            <Title>Co-Founder</Title>
            <Bio>
              Gabriel is an undergraduate at Harvard University studying
              statistics and computer science. He loves listening to all kinds
              of music and spending time with his family.
            </Bio>
          </Card>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Team;
