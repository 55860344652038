import styled from "styled-components";
import { Colors } from "../../../../styles/index";

export const ArticleContainer = styled.div`
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.7em;
  border: 1px solid ${Colors.highlight4};
  // box-shadow: 0 0 0.7rem rgba(31, 45, 61, 0.1);
  margin: 2em 0 2em 0;

  border-radius: 5px;
`;

export const Title = styled.h4`
  margin-top: 0px;
`;

export const Abstract = styled.p`
  font-size: 14px;
`;

export const ReadMore = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${Colors.secondary};
  font-weight: 600;
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  padding: 7px 15px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.outline ? "transparent" : `${Colors.highlight1}`};
  margin-left: 20px;
  border: ${(props) =>
    props.outline ? `2px solid ${Colors.highlight1}` : "none"};

  color: ${(props) =>
    props.outline || props.background ? `${Colors.highlight1}` : "#ffffff"};
  // color: ${(props) =>
    props.outline ? `${Colors.highlight1}` : `${Colors.highlight1}`};

  font-size: 14px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-weight: 600;
  transition: 0.2s;

  &:hover {
    color: ${(props) => (props.outline ? "#ffffff" : "#ffffff")};
    background-color: ${(props) =>
      props.outline ? `${Colors.highlight1}` : `${Colors.highlight1}`};
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-grow: 3;
  align-items: center;
`;
export const Icons = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  align-items: center;
`;

export const Count = styled.p`
  margin: 0px;
  color: rgb(135, 142, 149);
`;
