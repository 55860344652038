import React from 'react';
import { Paragraph } from './styles';

function Footer() {
  return (
      <Paragraph style={{color: "#C1B2AF"}}>
        nBrief 2020
      </Paragraph>
  );
}

export default Footer
